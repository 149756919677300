import { ReportStatus } from '@/support/ReportStatus';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { isValidDate, setFormattedDatePickerValue, dateErrorMessage, formatDate, formatDateSeconds } from '@/support/String';
import ErrorHandler from '@/support/ErrorHandler';
import { Report as ReportModel } from '@/models/Report';
import { User } from '@/models/User';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { Options } from '@/components/mi-dialog/MiDialog';
import { DateFormatter } from '@/support/DateFormatter';
import PlanningNoAppointmentDialog from '@/components/planningtool/planning-no-appointment-dialog/PlanningNoAppointmentDialog.vue';
import { NoAppointmentReason } from '@/components/planningtool/planning-no-appointment-dialog/PlanningNoAppointmentDialog';

@Component<Planning>({
  components: {
    PlanningNoAppointmentDialog,
  },
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDateSeconds(date);
    },
  },
})
export default class Planning extends Vue {
  public $pageTitle = 'Planning';

  protected reportModel: ReportModel = new ReportModel();

  protected isLoading = true;

  protected date = '';

  protected isEditingDate = false;

  protected dateTime = '';

  protected selectedUser = '';

  protected selectedSecondExpert = '';

  protected users: User[] = [];

  protected secondExperts: User[] = [];

  protected isSearchingExpert = false;

  protected debouncedSearchUsers: Function = debounce(this.handleSearchUser, 300);

  protected debouncedSearchSecondExpert: Function = debounce(this.handleSearchSecondExperts, 300);

  protected userFilter: UserFilter = {};

  protected userSearch = '';

  protected secondExpertSearch = '';

  protected isEditingPlannedAt = false;

  protected planned_at_date = '';

  protected planned_at_time = '13:00';

  protected reasons: NoAppointmentReason[] = [
    {
      label: 'Afspraak afgezegd',
      value: 'cancelled',
    },
    {
      label: 'Opnieuw inplannen',
      value: 'needs_replanning',
    },
  ];

  // approval bar
  protected approvalDialogOpen = false;

  protected isShowingNoAppointmentdialog = false;

  protected damageDescription = '';

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  protected status = false;

  protected mask: Mask = {
    time: '##:##',
  }

  protected databaseDate = '0000-00-00 00:00:00';

  protected plannedAt = new DateFormatter();

  public mounted() {
    this.date = DateTime.local().toFormat('yyyy-MM-dd');
    this.initialize();
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Planning Expert' },
        ],
      });
  }

  protected initialize() {
    this.isLoading = true;

    this.reportModel
      .include(['damages', 'answers', 'validations'])
      .find(this.$route.params.id)
      .then((report: ReportModel) => {
        this.reportModel = report;
        this.isLoading = false;
        this.initBreadcrumb();
        this.parsePlannedAt();
        this.isAllowedToViewDialog = true;
        if (report.status === 'planning') {
          this.status = true;
        } else {
          this.status = false;
        }

        if (report.expert) {
          this.selectedUser = report.expert.uuid || '';
          this.users.push(report.expert as User);
        } else {
          this.getUsers();
        }

        if (report.second_expert) {
          this.selectedSecondExpert = report.second_expert.uuid || '';
          this.secondExperts.push(report.second_expert as User);
        } else {
          this.getSecondExperts();
        }

        this.pingReport();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected parsePlannedAt() {
    this.plannedAt.selectedDate = this.reportModel.planned_at && this.reportModel.planned_at !== this.databaseDate
      ? this.reportModel.planned_at
      : '';

    this.plannedAt.selectedTime = this.reportModel.planned_at && this.reportModel.planned_at !== this.databaseDate
      ? formatDate(this.reportModel.planned_at, 'HH:mm')
      : '';
  }

  protected pingReport() {
    if (! this.reportModel || ! this.reportModel.uuid) {
      return;
    }

    this.reportModel
      .pingReport(this.reportModel.uuid)
      .then((report: ReportModel) => {
      //
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get dialogOptionsUnauthorized(): Options {
    return {
      title: 'Niet beschikbaar',
      text: 'Deze pagina is op dit moment niet beschikbaar',
      type: 'error',
      buttons: {
        confirm: {
          text: 'Terug naar dossier',
          action: () => {
            this.$router.push(`/reports/${this.reportModel.uuid}`);
          },
        },
      },
    };
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  // DATE
  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected dateFromDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected dateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  @Watch('date')
  protected dateChanged() {
    this.dateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingApprovedAtChanged() {
    if (! this.isEditingDate) {
      this.dateFromDatePicker();
    }
  }

  protected getSecondExperts() {
    new User()
      .all()
      .then((users: User[]) => {
        this.secondExperts = users;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getUsers() {
    new User()
      .all()
      .then((users: User[]) => {
        this.users = users;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected parseDate(date: string) {
    if (! date) {
      return '';
    }
    if (date.length < 12) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd-MM-yyyy');
    }
    return DateTime.fromFormat(date, 'yyyy-MM-dd hh:mm:ss').toFormat('dd-MM-yyyy');
  }

  protected parseTime(time: string) {
    if (time.includes(':')) {
      // eslint-disable-next-line no-self-assign
      time = time;
    } else {
      const timeSplit = time.match(/.{1,2}/g);

      if (timeSplit) {
        time = `${timeSplit[0]}:${timeSplit[1]}`;
      }
    }

    return `${time}:00`;
  }

  private clearSelectedExpert() {
    this.selectedSecondExpert = '';
    this.selectedUser = '';
  }

  private clearSelectedDateTime() {
    this.date = '';
    this.dateTime = '';
    this.planned_at_time = '';
  }

  protected handleSearchSecondExperts(query: any) {
    if (! query || ! query.target || ! query.target.value) {
      return;
    }

    new User()
      .filter('search', query.target.value)
      .limit(2000)
      .all()
      .then((users: User[]) => {
        this.secondExperts = users;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handleSearchUser(query: any) {
    if (! query || ! query.target || ! query.target.value) {
      return;
    }

    new User()
      .filter('search', query.target.value)
      .limit(2000)
      .all()
      .then((users: User[]) => {
        this.users = users;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get isDisabled(): boolean {
    const hasDateError = this.dateErrorMessage.length > 0;

    if (this.selectedUser && ! hasDateError) {
      return false;
    }

    return true;
  }

  protected openApprovalDialog() {
    if (this.isDisabled) {
      return;
    }

    this.approvalDialogOpen = true;
  }

  protected get toStatus() : string {
    if (this.reportModel.isBezwaarReport) {
      return ReportStatus.OBJECTION_IN_PROGRESS;
    }

    const expert = this.users.find((expert: User) => expert.uuid === this.selectedUser);
    if (expert && expert.is_opnemer) {
      return ReportStatus.OPNAME_IN_PROGRESS;
    }

    if (this.reportModel.isStuwmeerRegelingReport) {
      return ReportStatus.SMR_IN_PROGRESS;
    }

    return ReportStatus.IN_PROGRESS;
  }

  protected handleConfirmPlanningNoAppointment() {
    this.$router.push(`/reports/${this.reportModel.uuid}`);
  }

  @Watch('userSearch')
  protected userSearchChanged(query: string) {
    if (query && query.length) {
      this.userFilter.search = query;
      this.debouncedSearchUsers(query);
    }
  }

  @Watch('secondExpertSearch')
  protected secondExpertSearchChanged(query: string) {
    if (query && query.length) {
      this.userFilter.search = query;
      this.debouncedSearchSecondExpert(query);
    }
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    //
  }

  @Watch('formattedDate')
  @Watch('planned_at_date')
  @Watch('planned_at_time')
  public planned_at_dateChanged() {
    this.reportModel.planned_at = `${this.planned_at_date} ${this.planned_at_time}`;
    this.dateTime = `${this.date} ${this.parseTime(this.planned_at_time)}`;
  }

  @Watch('plannedAt.selectedDate')
  @Watch('plannedAt.selectedTime')
  public plannedAtChanged() {
    this.reportModel.planned_at = `${this.plannedAt.selectedDate} ${this.plannedAt.selectedTime}:00`;
    this.dateTime = `${this.plannedAt.selectedDate} ${this.plannedAt.selectedTime}:00`;
  }
}

interface Mask {
  [key: string]: string;
}

interface UserFilter {
  search?: string;
}
