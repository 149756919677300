var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container--md-50" },
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.reportModel, editor: "planning_expert" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "elementPanel elementPanel__sub" },
                    [
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c("v-flex", [
                              _c("h2", { staticClass: "sectionTitle" }, [
                                _vm._v("Opnemer en Deskundige")
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { staticClass: "field", attrs: { xs12: "" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.users,
                                    "search-input": _vm.userSearch,
                                    label: "Opnemer",
                                    "item-text": "name",
                                    "item-value": "uuid",
                                    "hide-details": "",
                                    clearable: ""
                                  },
                                  on: {
                                    "update:searchInput": function($event) {
                                      _vm.userSearch = $event
                                    },
                                    "update:search-input": function($event) {
                                      _vm.userSearch = $event
                                    },
                                    keyup: _vm.debouncedSearchUsers
                                  },
                                  model: {
                                    value: _vm.selectedUser,
                                    callback: function($$v) {
                                      _vm.selectedUser = $$v
                                    },
                                    expression: "selectedUser"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "field", attrs: { xs12: "" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.secondExperts,
                                    "search-input": _vm.secondExpertSearch,
                                    label:
                                      "[OPTIONEEL] Deskundige (indien afwijkt van de opnemer)",
                                    "item-text": "name",
                                    "item-value": "uuid",
                                    "hide-details": "",
                                    clearable: "",
                                    disabled:
                                      !_vm.selectedUser ||
                                      !_vm.selectedUser.length
                                  },
                                  on: {
                                    "update:searchInput": function($event) {
                                      _vm.secondExpertSearch = $event
                                    },
                                    "update:search-input": function($event) {
                                      _vm.secondExpertSearch = $event
                                    },
                                    keyup: _vm.debouncedSearchSecondExpert
                                  },
                                  model: {
                                    value: _vm.selectedSecondExpert,
                                    callback: function($$v) {
                                      _vm.selectedSecondExpert = $$v
                                    },
                                    expression: "selectedSecondExpert"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      [
                        _c("h2", { staticClass: "sectionTitle" }, [
                          _vm._v("Datum en Tijd")
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "dialog",
                                attrs: {
                                  "close-on-content-click": false,
                                  lazy: "",
                                  "nudge-right": "100",
                                  "full-width": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "input input-date",
                                  attrs: {
                                    slot: "activator",
                                    placeholder: "DD-MM-JJJJ",
                                    label: "Inplannen op",
                                    "error-messages": _vm.plannedAt.errorMessage
                                  },
                                  slot: "activator",
                                  model: {
                                    value: _vm.plannedAt.formattedDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.plannedAt,
                                        "formattedDate",
                                        $$v
                                      )
                                    },
                                    expression: "plannedAt.formattedDate"
                                  }
                                }),
                                _c("v-date-picker", {
                                  ref: "picker",
                                  attrs: {
                                    "first-day-of-week": "1",
                                    locale: "nl-nl",
                                    min: "1910-01-01",
                                    color: "#837f16"
                                  },
                                  model: {
                                    value: _vm.plannedAt.selectedDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.plannedAt,
                                        "selectedDate",
                                        $$v
                                      )
                                    },
                                    expression: "plannedAt.selectedDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              staticClass: "input input-time",
                              attrs: {
                                slot: "activator",
                                mask: _vm.mask.time,
                                label: "Inplannen op (tijd)",
                                "prepend-icon": "access_time",
                                "error-messages":
                                  _vm.plannedAt.errorMessageTime,
                                placeholder: "13:00"
                              },
                              slot: "activator",
                              model: {
                                value: _vm.plannedAt.selectedTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.plannedAt, "selectedTime", $$v)
                                },
                                expression: "plannedAt.selectedTime"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("ApprovalBar", {
        attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "actionContainer" },
                  [
                    _vm.reportModel.isBezwaarReport
                      ? [
                          _c(
                            "MiButton",
                            {
                              attrs: {
                                color: "success",
                                small: "true",
                                icon: "check_circle",
                                disabled: _vm.isDisabled
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openApprovalDialog($event)
                                }
                              }
                            },
                            [_vm._v(" Deskundige toewijzen ")]
                          )
                        ]
                      : [
                          _c(
                            "MiButton",
                            {
                              staticClass: "mr-4",
                              attrs: {
                                color: "alert",
                                small: "true",
                                icon: "backspace"
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.isShowingNoAppointmentdialog = true
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v("Terugzetten: Planning Expertisebureau")
                              ])
                            ]
                          ),
                          _c(
                            "MiButton",
                            {
                              attrs: {
                                color: "success",
                                small: "true",
                                icon: "check_circle",
                                disabled: _vm.isDisabled
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openApprovalDialog($event)
                                }
                              }
                            },
                            [_vm._v(" Inplannen ")]
                          )
                        ]
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.approvalDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.approvalDialogOpen,
                callback: function($$v) {
                  _vm.approvalDialogOpen = $$v
                },
                expression: "approvalDialogOpen"
              }
            },
            [
              _c("ApprovalDialog", {
                attrs: {
                  report: _vm.reportModel,
                  title: "Verander naar:",
                  status: _vm.toStatus,
                  expert: _vm.selectedUser,
                  secondExpert: _vm.selectedSecondExpert,
                  dateTime: _vm.dateTime
                },
                model: {
                  value: _vm.approvalDialogOpen,
                  callback: function($$v) {
                    _vm.approvalDialogOpen = $$v
                  },
                  expression: "approvalDialogOpen"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: true, "max-width": "600px" },
          model: {
            value: _vm.isShowingNoAppointmentdialog,
            callback: function($$v) {
              _vm.isShowingNoAppointmentdialog = $$v
            },
            expression: "isShowingNoAppointmentdialog"
          }
        },
        [
          _vm.isShowingNoAppointmentdialog
            ? _c("PlanningNoAppointmentDialog", {
                attrs: { report: _vm.reportModel, reasons: _vm.reasons },
                on: {
                  noAppointmentConfirm: _vm.handleConfirmPlanningNoAppointment
                },
                model: {
                  value: _vm.isShowingNoAppointmentdialog,
                  callback: function($$v) {
                    _vm.isShowingNoAppointmentdialog = $$v
                  },
                  expression: "isShowingNoAppointmentdialog"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }